.welcomeWrapper{
    font-family: 'Pixelify Sans';
    font-size: 60px;
    max-width: 100%;
    min-width: 420px;
    align-items: center;
    background: #e79700;
    border: 2px solid #e79700;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 5px 5px;
}
.welcome{
    width: 100%;
    font-size: 30px;
    text-align: center;
    margin-bottom: 30px;
    
}

.settingButton{
    
    width: 100%;
    height: 45px;
    background: #5bd581;
    border: none;
    outline: none;
    border-radius: 10px;
    font-size: 24px;
    
    font-family: 'Pixelify Sans';
    box-shadow: 5px 5px black;

}
.settingButton:hover{
    color: white;
}
.settingButton:active{
    color: black;
    box-shadow: 5px 5px black inset;
}