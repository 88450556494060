.wrapper{
    font-family: 'Pixelify Sans';
    max-width: 80%;
    min-width: 420px;
    
    background: #e79700;
    padding: 30px;
    border-radius: 5%;
    box-shadow: 5px 5px;
}
.wrapper h1{
    font-size: 36px;
    text-align: center;
    margin-bottom: 30px;
}

::placeholder{
    font-family: 'Pixelify Sans';
}
input{
    font-family: 'Pixelify Sans';
    font-size: 20px;
}
.input-box{
    position: relative;
}
.input-box input{
    min-height: 35px;
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: 5px;
    border-bottom: 2px solid black;
    margin: 5px;
}

.input-box .icon{
    margin-right: auto;
    font-size: 20px;
    position: absolute;
    right: 0;
    top: 30%;
}

.wrapper button{
    margin-top: 5%;
    width: 100%;
    height: 45px;
    background: #5bd581;
    border: none;
    outline: none;
    border-radius: 10px;
    font-size: 24px;
    font-family: 'Pixelify Sans';
    box-shadow: 5px 5px;
}


.wrapper button:active{
    margin-top: 5%;
    width: 100%;
    height: 45px;
    background: #5bd581;
    border: none;
    outline: none;
    border-radius: 10px;
    font-size: 24px;
    font-family: 'Pixelify Sans';
    box-shadow: 5px 5px inset;
}

.remember-forgot{
    margin-top: 30px;
}
.forgotPassword{
    margin-top: 30px;
    width: 100%;
    align-items: center;
}