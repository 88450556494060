.wrapper{
    font-family: 'Pixelify Sans';
    max-width: 100%;
    min-width: 420px;
    align-items: center;
    background: #e79700;
    border: 2px solid #e79700;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 5px 5px;
}
.welcome{
    width: 100%;
    font-size: 30px;
    text-align: center;
    margin-bottom: 30px;
    
}

.button{
    
    width: 100%;
    height: 45px;
    background: #5bd581;
    border: none;
    outline: none;
    border-radius: 10px;
    font-size: 24px;
    
    font-family: 'Pixelify Sans';
    box-shadow: 5px 5px black;

}
.utton:hover{
    color: white;
}
.button:active{
    color: black;
    box-shadow: 5px 5px black inset;
}

::placeholder{
    font-family: 'Pixelify Sans';
}
input{
    font-family: 'Pixelify Sans';
    font-size: 20px;
}
.input-box{
    position: relative;
}
.input-box input{
    min-height: 35px;
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: 5px;
    border-bottom: 2px solid black;
    margin: 5px;
}

.input-box .icon{
    margin-right: auto;
    font-size: 20px;
    position: absolute;
    right: 0;
    top: 30%;
}

.deleteButton:disabled{
    box-shadow: 5px 5px red inset
}